define("discourse/plugins/discourse-lexicon-plugin/discourse/deeplink-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("lexicon-deeplink", {
      path: "/lexicon/deeplink/*link"
    });
    this.route("deeplink", {
      path: "/deeplink/*link"
    });
  }
});