define("discourse/plugins/discourse-lexicon-plugin/discourse/templates/deeplink", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <BaseDeeplinkTemplate @model={{this.model}} />
  
  */
  {
    "id": "jAsWBZhX",
    "block": "[[[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"]],[],false,[\"base-deeplink-template\"]]",
    "moduleName": "discourse/plugins/discourse-lexicon-plugin/discourse/templates/deeplink.hbs",
    "isStrictMode": false
  });
});